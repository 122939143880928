/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { HostBinding, Input, Component, ElementRef, Renderer2, isDevMode, NgModule } from '@angular/core';
import { validatePackage } from '@progress/kendo-licensing';
import { DomSanitizer } from '@angular/platform-browser';
import { isDocumentAvailable } from '@progress/kendo-angular-common';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-icons',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1608133888,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

const sizeClasses = {
    'default': 'k-icon',
    'xsmall': 'k-icon-xs',
    'small': 'k-icon-sm',
    'medium': 'k-icon-md',
    'large': 'k-icon-lg',
    'xlarge': 'k-icon-xl'
};
/**
 * @hidden
 */
class IconBase {
    constructor(element, renderer) {
        this.element = element;
        this.renderer = renderer;
        validatePackage(packageMetadata);
    }
    get horizontalFlip() {
        return this.flip === 'horizontal' || this.flip === 'both';
    }
    get verticalFlip() {
        return this.flip === 'vertical' || this.flip === 'both';
    }
    /**
     * Specifies the theme color for the Icon.
     *
     * The possible values are:
     * * `inherit` (Default)&mdash;Applies coloring based on the current color.
     * * `primary` &mdash;Applies coloring based on primary theme color.
     * * `secondary`&mdash;Applies coloring based on secondary theme color.
     * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
     * * `info`&mdash;Applies coloring based on info theme color.
     * * `success`&mdash; Applies coloring based on success theme color.
     * * `warning`&mdash; Applies coloring based on warning theme color.
     * * `error`&mdash; Applies coloring based on error theme color.
     * * `dark`&mdash; Applies coloring based on dark theme color.
     * * `light`&mdash; Applies coloring based on light theme color.
     * * `inverse`&mdash; Applies coloring based on inverse theme color.
     *
     */
    set themeColor(themeColor) {
        const element = this.element.nativeElement;
        this._themeColor = themeColor;
        this.renderer.removeClass(element, `k-color-${themeColor}`);
        if (themeColor) {
            this.renderer.addClass(element, `k-color-${themeColor}`);
        }
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the size of the Icon.
     *
     * The possible values are:
     * * `default` (Default) (Font-size: 16px; Width: 16px; Height: 16px)
     * * `xsmall` (Font-size: 8px; Width: 8px; Height: 8px;)
     * * `small` (Font-size: 12px; Width: 12px; Height: 12px;)
     * * `medium` (Font-size: 32px; Width: 32px; Height: 32px;)
     * * `large` (Font-size: 48px; Width: 48px; Height: 48px;)
     * * `xlarge` (Font-size: 64px; Width: 64px; Height: 64px;)
     *
     */
    set size(size) {
        const element = this.element.nativeElement;
        this._size = size;
        const sizeClass = sizeClasses[this.size];
        this.renderer.removeClass(element, sizeClass);
        if (size !== 'default') {
            this.renderer.addClass(element, sizeClass);
        }
    }
    get size() {
        return this._size;
    }
}
__decorate([
    HostBinding('class.k-flip-h'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], IconBase.prototype, "horizontalFlip", null);
__decorate([
    HostBinding('class.k-flip-v'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], IconBase.prototype, "verticalFlip", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], IconBase.prototype, "flip", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], IconBase.prototype, "themeColor", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], IconBase.prototype, "size", null);

/**
 * Represents the Kendo UI Icon component for Angular.
 */
let IconComponent = class IconComponent extends IconBase {
    constructor(element, renderer) {
        super(element, renderer);
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
    }
    /**
     * Defines the name for an existing icon in a Kendo UI theme, which will be rendered.
     * All [Kendo UI Icons]({% slug icons %}#toc-list-of-font-icons) are supported.
     */
    set name(name) {
        const element = this.element.nativeElement;
        this._name = name;
        this.renderer.removeClass(element, `k-i-${this.name}`);
        if (name) {
            this.renderer.addClass(element, `k-i-${this.name}`);
        }
    }
    get name() {
        return this._name;
    }
};
__decorate([
    HostBinding('class.k-icon'),
    __metadata("design:type", Boolean)
], IconComponent.prototype, "hostClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], IconComponent.prototype, "name", null);
IconComponent = __decorate([
    Component({
        exportAs: 'kendoIcon',
        selector: 'kendo-icon',
        template: ``
    }),
    __metadata("design:paramtypes", [ElementRef, Renderer2])
], IconComponent);

/**
 * Represents the Kendo UI SVG Icon component for Angular.
 */
let SVGIconComponent = class SVGIconComponent extends IconBase {
    constructor(domSanitizer, element, renderer) {
        super(element, renderer);
        this.domSanitizer = domSanitizer;
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
    }
    /**
     * Defines the SVG icon, which will be rendered.
     */
    set icon(icon) {
        const element = this.element.nativeElement;
        if (this._icon && this._icon.name) {
            this.renderer.removeClass(element, `k-svg-i-${this._icon.name}`);
        }
        this._icon = icon;
        this.renderer.addClass(element, `k-svg-i-${this._icon.name}`);
    }
    get icon() {
        return this._icon;
    }
    get content() {
        this.safeContent = this.safeContent || this.domSanitizer.bypassSecurityTrustHtml(this.icon.content);
        return this.safeContent;
    }
    get visible() {
        return this.icon && isDocumentAvailable();
    }
    ngOnInit() {
        this.verifyIconProperty();
    }
    verifyIconProperty() {
        if (!isDevMode()) {
            return;
        }
        if (!this._icon) {
            throw new Error(`
                Invalid configuration.
                The input [icon] is required for the Kendo UI SVG Icon component for Angular.
                See https://www.telerik.com/kendo-angular-ui/components/icons/svg-icon
            `);
        }
    }
};
__decorate([
    HostBinding('class.k-svg-icon'),
    __metadata("design:type", Boolean)
], SVGIconComponent.prototype, "hostClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], SVGIconComponent.prototype, "icon", null);
SVGIconComponent = __decorate([
    Component({
        exportAs: 'kendoSVGIcon',
        selector: 'kendo-svg-icon',
        template: `
        <svg *ngIf="visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             [attr.viewBox]="icon.viewBox" [innerHTML]="content">
        </svg>`
    }),
    __metadata("design:paramtypes", [DomSanitizer, ElementRef, Renderer2])
], SVGIconComponent);

const exportedModules = [
    IconComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icon module
 * import { IconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconModule], // import Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let IconModule = class IconModule {
};
IconModule = __decorate([
    NgModule({
        declarations: [declarations],
        exports: [exportedModules],
        imports: [CommonModule]
    })
], IconModule);

const exportedModules$1 = [
    SVGIconComponent
];
const declarations$1 = [
    ...exportedModules$1
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the SVG Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the SVG Icon module
 * import { SVGIconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, SVGIconModule], // import SVG Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let SVGIconModule = class SVGIconModule {
};
SVGIconModule = __decorate([
    NgModule({
        declarations: [declarations$1],
        exports: [exportedModules$1],
        imports: [CommonModule]
    })
], SVGIconModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Icons components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icons module
 * import { IconsModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconsModule], // import Icons module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let IconsModule = class IconsModule {
};
IconsModule = __decorate([
    NgModule({
        exports: [
            IconModule,
            SVGIconModule
        ]
    })
], IconsModule);

/**
 * Generated bundle index. Do not edit.
 */

export { IconBase, IconComponent, SVGIconComponent, IconModule, SVGIconModule, IconsModule };
